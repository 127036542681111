<template>
  <div class="max-h-[500px] overflow-y-auto rank-rating">
    <div v-if="loading" class="flex items-center justify-center">
      <el-skeleton animated :rows="10" />
    </div>
    <div v-else>
      <div
        v-if="meRating"
        class="sticky top-0 bg-[#FED9CD] z-10 mb-2 border-b p-1 rounded-md"
      >
        <div class="flex items-center justify-between mb-1">
          <div class="flex items-center gap-1">
            <ProfileImage
              :student_id="meRating.student_id"
              size="small"
              :preview="false"
              :image-url="meRating.avatar"
              :rank="meRating.rank?.title"
            />
            <div>
              <p
                class="text-md font-bold text-darkBlue capitalize text-lg leading-none mb-1"
              >
                {{ meRating.first_name }} {{ meRating.last_name }}
              </p>
              <p class="text-xs font-bold leading-none">
                {{ $t("in-rating") }} {{ meRating.order }} - {{ $t("place") }}
              </p>
            </div>
          </div>
          <div class="flex items-center gap-1">
            <div class="flex items-center gap-1">
              <p class="text-sm font-bold">
                {{ numeral(meRating.xp).format("0,0") }}
              </p>

              <img
                src="@/assets/images/typing-rating/quvvat.png"
                alt="xp"
                class="w-4 h-4 object-contain"
              />
            </div>
            <span
              class="text-xs font-bold leading-none capitalize rank-title"
              :class="meRating.rank?.title?.toLowerCase() || 'iron'"
            >
              {{ meRating.rank?.title || "iron" }}
            </span>
            <img
              :src="
                meRating.rank?.image
                  ? mediaLink + meRating.rank?.image
                  : defaultRank
              "
              alt="rank"
              class="w-10 h-10 object-contain"
            />
          </div>
        </div>
      </div>
      <div
        v-if="ratingList.length"
        v-for="student in ratingList"
        :key="student.id"
        class="w-full"
      >
        <div
          v-if="!student.me"
          class="flex items-center justify-between w-full mt-2 p-1"
        >
          <div class="flex items-center justify-between mb-1 w-full">
            <div class="flex items-center gap-1">
              <ProfileImage
                :student_id="student.student_id"
                size="exsmall"
                :preview="false"
                :image-url="student.avatar"
                :rank="student.rank?.title"
              />
              <div>
                <p
                  class="text-md font-bold text-darkBlue capitalize text-lg leading-none mb-1"
                >
                  {{ student.first_name }} {{ student.last_name }}
                </p>
                <p class="text-xs font-bold leading-none">
                  {{ $t("in-rating") }} {{ student.order }} - {{ $t("place") }}
                </p>
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="flex items-center">
                <p class="text-sm font-bold">
                  {{ numeral(student.xp).format("0,0") }}
                </p>

                <img
                  src="@/assets/images/typing-rating/quvvat.png"
                  alt="xp"
                  class="w-4 h-4 object-contain"
                />
              </div>
              <span
                class="text-xs font-bold leading-none capitalize rank-title"
                :class="student.rank?.title?.toLowerCase() || 'iron'"
              >
                {{ student.rank?.title || "iron" }}
              </span>
              <img
                :src="
                  student.rank?.image
                    ? mediaLink + student.rank?.image
                    : defaultRank
                "
                alt="rank"
                class="w-10 h-10 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center w-full my-10 justify-center">
        <p class="text-center text-sm font-bold text-darkBlue">
          {{ $t("no-rank-rating") }}
        </p>
      </div>
    </div>

    <div class="flex justify-center w-full sticky bottom-0 bg-white">
      <el-pagination
        v-if="pageCount > 1"
        layout="prev, pager, next"
        v-model:current-page="page"
        :page-size="10"
        :total="pageCount * 10"
        @current-change="getRatingList"
      />
    </div>
  </div>
</template>

<script setup>
import apiV2 from "@/apiConfig/apiV2";
import { ref, onMounted, computed } from "vue";
import ProfileImage from "@/entities/ProfileImage/ProfileImage.vue";
import defaultRank from "@/assets/default-rank.png";
import { mediaLink } from "@/apiConfig/mediaLink";
import numeral from "numeral";

const ratingList = ref([]);
const pageCount = ref(0);
const page = ref(1);
const loading = ref(false);

async function getRatingList() {
  loading.value = true;
  ratingList.value = await apiV2
    .get("/student/xp/rating", {
      params: {
        page: page.value,
      },
    })
    .then((res) => {
      pageCount.value = res.data?.page_count;
      return res.data?.data;
    })
    .catch(() => []);
  loading.value = false;
}

const meRating = computed(() => {
  return ratingList.value.find((student) => student.me) || null;
});

onMounted(() => {
  getRatingList();
});
</script>

<style lang="scss">
.rank-rating {
  .rank-title {
    font-size: 10px;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    &.iron {
      background-color: #505b65;
      color: #8799aa;
    }
    &.bronze {
      background-color: #c26e2a;
      color: #ff9641;
    }

    &.silver {
      background-color: #96acca;
      color: #c9dcf6;
    }

    &.gold {
      background-color: #fdc61d;
      color: #ffe699;
    }

    &.platinum {
      background: linear-gradient(
        149.68deg,
        #f3c187 14.88%,
        #e22bed 38.01%,
        #4c4dea 64.7%,
        #23bfd3 86.06%
      );
      color: white;
    }
    &.marsianin {
      background: linear-gradient(178.07deg, #0b3665 1.68%, #0195ec 61.75%);
      color: white;
    }
  }
}
</style>
