<template>
  <div>
    <el-alert
      v-if="lessonStatus === 'open'"
      type="info"
      class="mb-3"
      :closable="false"
      show-icon
    >
      <p class="text-sm">{{ t("additional-lesson-info") }}</p>
    </el-alert>
    <el-alert
      v-else-if="lessonStatus === 'canceled'"
      type="error"
      class="mb-3"
      :closable="false"
      show-icon
    >
      <p class="text-sm">{{ t("additional-lesson-canceled") }}</p>
    </el-alert>
    <el-alert
      v-else-if="lessonStatus === 'not_came'"
      type="warning"
      class="mb-3"
      :closable="false"
      show-icon
    >
      <p class="text-sm">{{ t("additional-lesson-not-came") }}</p>
    </el-alert>
    <el-alert
      v-else-if="lessonStatus === 'came'"
      type="success"
      class="mb-3"
      :closable="false"
      show-icon
    >
      <p class="text-sm">{{ t("additional-lesson-booked") }}</p>
    </el-alert>
    <div class="mt-3">
      <p class="text-lg font-bold">{{ t("select-tutor") }}</p>
      <el-select
        v-model="selectedTutor"
        :placeholder="t('select-tutor')"
        class=""
        clearable
        :disabled="lessonStatus !== 'open'"
      >
        <el-option
          v-for="tutor in tutors"
          :key="tutor.id"
          :value="tutor.id"
          :label="tutor.first_name + ' ' + tutor.last_name"
        >
          <div class="flex items-center">
            <img
              v-if="tutor.profile?.avatar?.length > 0"
              :src="mediaLink + tutor.profile?.avatar"
              alt="tutor image"
              class="w-8 h-8 rounded-full"
            />
            <img
              v-else
              src="@/assets/default-user-img.jpg"
              alt="tutor image"
              class="w-8 h-8 rounded-full"
            />
            <p class="ml-2">
              {{ tutor.first_name + " " + tutor.last_name }} /
              {{ tutor.position?.join("/") }}
            </p>
          </div>
        </el-option>
      </el-select>
    </div>
    <div class="mt-3">
      <p class="text-lg font-bold">{{ t("select-date") }}</p>
      <el-select
        :disabled="lessonStatus !== 'open'"
        clearable
        v-model="selectedDay"
        :placeholder="t('select-date')"
      >
        <el-option
          v-for="day in weekDays"
          :key="day.date"
          :value="day.date"
          :label="$t(day.day) + ' (' + day.date + ')'"
          :disabled="!availableLessonsDates.includes(day.date)"
        >
          <p class="text-md">
            <span class="w-[100px] inline-block">
              {{ $t(day.day) }}
            </span>
            {{ "(" + day.date + ")" }}
          </p>
        </el-option>
      </el-select>
    </div>
    <div class="mt-3">
      <p class="text-lg font-bold">{{ t("select-time") }}</p>
      <el-select
        :disabled="lessonStatus !== 'open'"
        clearable
        v-model="selectedSlot"
        :placeholder="t('select-time')"
      >
        <el-option
          v-for="slot in slots"
          :key="slot.id"
          :value="slot.id"
          :label="
            slot.from_hour.slice(0, 5) + ' - ' + slot.till_hour.slice(0, 5)
          "
        >
        </el-option>
      </el-select>
    </div>
    <div class="mt-3">
      <p class="text-lg font-bold">{{ t("write-lesson") }}</p>
      <el-input
        :disabled="lessonStatus !== 'open'"
        v-model="additionalLessonInfo"
        :placeholder="t('write-lesson')"
        type="textarea"
        rows="3"
      ></el-input>
    </div>
    <div class="mt-3">
      <button
        v-if="lessonStatus === 'open'"
        @click="bookAdditionalLesson"
        class="bg-primary500 text-white px-4 py-2 rounded-md w-full disabled:opacity-30"
        :disabled="
          !selectedTutor ||
          !selectedDay ||
          !selectedSlot ||
          !additionalLessonInfo?.length
        "
      >
        {{ $t("write-additional-lesson") }}
      </button>
      <button
        v-else-if="lessonStatus === 'not_came'"
        @click="cancelAdditionalLesson"
        class="bg-red-500 text-white px-4 py-2 rounded-md w-full disabled:opacity-30"
      >
        {{ $t("cancel-additional-lesson") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import api from "@/apiConfig/api";
import { ref, onMounted, computed, watch } from "vue";
import store from "@/store";
import { mediaLink } from "@/apiConfig/mediaLink";
import { DateTime } from "luxon";
import apiV2 from "@/apiConfig/apiV2";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

const teachers = ref([]);
const studentInfo = computed(() => store.state.student.studentInfo);

const emit = defineEmits(["closeModal"]);
const props = defineProps({
  day: {
    type: String,
    default: "",
  },
  lessonStatus: {
    type: String,
    default: "open",
  },
  tutor: {
    type: Number,
    default: null,
  },
  slot: {
    type: Number,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
  id: {
    type: Number,
    default: null,
  },
  availableLessonsDates: {
    type: Array,
    default: [],
  },
});
const selectedDay = ref(props.day || null);
const selectedTutor = ref(props.tutor || null);
const selectedSlot = ref(props.slot || null);
const additionalLessonInfo = ref(props.theme || "");
watch(
  () => props.day,
  () => {
    selectedDay.value = props.day;
    selectedTutor.value = props.tutor;
    selectedSlot.value = props.slot;
    additionalLessonInfo.value = props.theme;
  }
);

const { t } = useI18n();

const getTeachers = async () => {
  teachers.value = await api
    .get("/users", {
      params: {
        role: "teacher",
        branch_id: studentInfo.value.company_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });

  filterTeachers();
};
const tutors = ref([]);
const filterTeachers = () => {
  tutors.value = teachers.value.filter((teacher) => teacher.is_tutor);
};
onMounted(() => {
  getTeachers();
  getWeekDays();
  getSlots();
});

const weekDays = ref([]);
const getWeekDays = () => {
  const today = DateTime.now();
  weekDays.value = [];

  for (let i = 0; i <= 6; i++) {
    const date = today.plus({ days: i });
    if (date.weekday !== 7) {
      weekDays.value.push({
        date: date.toFormat("yyyy-MM-dd"),
        day: date.toFormat("EEEE"),
      });
    }
  }
};

const slots = ref([]);
const getSlots = async () => {
  slots.value = await api
    .get("/users/slots_list")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

const bookAdditionalLesson = async () => {
  apiV2
    .post("/courses/extra_lessons", {
      tutor_id: selectedTutor.value,
      for_date: selectedDay.value,
      slot_id: selectedSlot.value,
      theme: additionalLessonInfo.value,
      student_id: studentInfo.value.id,
    })
    .then(() => {
      ElMessage.success(t("additional-lesson-success"));
      emit("closeModal");
      selectedTutor.value = null;
      selectedDay.value = null;
      selectedSlot.value = null;
      additionalLessonInfo.value = "";
    })
    .catch(() => {
      ElMessage.error(t("additional-lesson-error"));
    });
};

const cancelAdditionalLesson = async () => {
  await api
    .patch("/course_elements/extra_lessons", {
      id: props.id,
      status: "canceled",
    })
    .then(() => {
      ElMessage.success("Darsni bekor qildingiz");
      emit("closeModal");
    })
    .catch(() => {
      ElMessage.error("Darsni bekor qilishda xatolik");
    });
};
</script>
