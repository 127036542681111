<template>
  <el-dialog
    v-model="visible"
    :title="$t('survey')"
    width="600"
    class="rounded-lg"
    align-center
    v-if="activeQuestion"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div>
      <div>
        <p class="text-lg font-bold">
          {{ activeQuestionIndex + 1 }}
          {{ survey.questions[activeQuestionIndex].question_text }}
        </p>
        <div v-if="activeQuestion?.question_type === 'short'" class="mt-2">
          <el-input
            placeholder="Javobni kiriting"
            clearable
            v-model="surveyAnswers[activeQuestionIndex].answer_text"
          />
        </div>
        <div
          v-else-if="activeQuestion.question_type === 'paragraph'"
          class="mt-2"
        >
          <el-input
            class="w-full"
            placeholder="Javobni kiriting"
            clearable
            type="textarea"
            v-model="surveyAnswers[activeQuestionIndex].answer_text"
          />
        </div>
        <div
          v-else-if="activeQuestion.question_type === 'multiple'"
          class="mt-2"
        >
          <el-checkbox-group
            v-model="surveyAnswers[activeQuestionIndex].selected_option_ids"
            clearable
          >
            <el-checkbox
              v-for="option in activeQuestion.options"
              :key="option.id"
              :label="option.option_text"
              :value="option.id"
            />
          </el-checkbox-group>
        </div>
        <div v-else-if="activeQuestion.question_type === 'single'">
          <el-radio-group
            v-model="surveyAnswers[activeQuestionIndex].selected_option_ids[0]"
            clearable
          >
            <el-radio
              v-for="option in activeQuestion.options"
              :key="option.id"
              :label="option.option_text"
              :value="option.id"
            />
          </el-radio-group>
        </div>
        <div
          v-else-if="activeQuestion.question_type === 'dropdown'"
          class="mt-3"
        >
          <el-select
            clearable
            placeholder="Javobni tanlang"
            v-model="surveyAnswers[activeQuestionIndex].selected_option_ids[0]"
            filterable
          >
            <el-option
              v-for="option in activeQuestion.options"
              :key="option.id"
              :label="option.option_text"
              :value="option.id"
            />
          </el-select>
        </div>
        <div
          v-else-if="activeQuestion.question_type === 'number'"
          class="mt-2 text-left"
        >
          <el-input-number
            v-model="surveyAnswers[activeQuestionIndex].answer_text"
            :controls="false"
            class="w-full"
            placeholder="Javobni kiriting"
            :min="1"
            clearable
          />
        </div>

        <div class="text-right mt-3">
          <el-button
            type="primary"
            @click="nextQuestion"
            :disabled="
              surveyAnswers[activeQuestionIndex].answer_text.length === 0 &&
              surveyAnswers[activeQuestionIndex].selected_option_ids.length ===
                0
            "
            >{{
              activeQuestionIndex !== survey.questions.length - 1
                ? $t("signup.next")
                : $t("login.btn-send")
            }}</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import api from "@/apiConfig/api";
import apiV2 from "@/apiConfig/apiV2";
import store from "@/store";
import { ElMessage } from "element-plus";
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const visible = ref(true);
const survey = ref(null);
const activeQuestionIndex = ref(0);
const activeQuestion = computed(
  () => survey.value?.questions[activeQuestionIndex.value] || null
);
const surveyAnswers = ref([]);
const studentInfo = computed(() => store.state.student.studentInfo);
const surveyId = ref(null);

const { t } = useI18n();

async function getSurvey() {
  survey.value = await apiV2
    .get(`/reports/form-surveys/${surveyId.value}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });

  createSurveyAnswersModel();
}

function createSurveyAnswersModel() {
  surveyAnswers.value = [];

  if (!survey.value?.questions?.length) return;

  survey.value.questions.forEach((question) => {
    surveyAnswers.value.push({
      question_id: question.id,
      student_id: studentInfo.value.id,
      selected_option_ids: [],
      answer_text: "",
    });
  });
}

function clearSurveyAnswers() {
  surveyAnswers.value = [];
  activeQuestionIndex.value = 0;
  survey.value = null;
  surveyId.value = null;
  getUnansweredForms();
}

async function nextQuestion() {
  activeQuestionIndex.value++;
  if (activeQuestionIndex.value === survey.value.questions.length) {
    await apiV2
      .post("/reports/form-student-answer/", surveyAnswers.value)
      .then(() => {
        ElMessage.success(t("survey-success"));
      })
      .catch(() => {
        ElMessage.error(t("survey-error"));
      });

    clearSurveyAnswers();
  }
}

async function getUnansweredForms() {
  const unansweredForms = await apiV2
    .get(`/reports/form-surveys/unanswered/${studentInfo.value.id}`)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return [];
    });
  if (unansweredForms.length) {
    surveyId.value = unansweredForms[0].survey_id;
    getSurvey();
  }
}

watch(
  () => studentInfo.value,
  () => {
    getUnansweredForms();
  }
);
</script>
