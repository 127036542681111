import { createRouter, createWebHistory } from "vue-router";
import SpaceShopView from "../modules/SpaceShop/SpaceShop.vue";
import SpaceShopHistory from "../modules/SpaceShopHistory/SpaceShopHistory.vue";
import RatingView from "../views/RatingView.vue";
import NewsInfoView from "../views/NewsInfoView.vue";
import AttendanceView from "../views/AttendanceView.vue";
import ParentMain from "../modules/ParentPart/ParentPartView.vue";
import axios from "axios";
import store from "@/store";

const checkLayout = () => {
  let page = {
    view: null,
    layout: "",
  };
  if (window.innerWidth >= 700) {
    // page.view = MainDeskView;
    page.layout = "desktop";
  } else {
    // page.view = MainMobView;
    page.layout = "mobile";
  }
  return page;
};

window.addEventListener("resize", checkLayout);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/pages/LogIn/LogIn.vue"),
    meta: {
      layout: "auth",
      auth: false,
      role: "student",
    },
  },
  {
    path: "/offerta",
    name: "offerta",
    component: () => import("@/pages/OffertaPage/OffertaPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
      role: "student",
    },
  },

  {
    path: "/signup",
    name: "signup",
    component: () => import("@/pages/SignUp/SignUp.vue"),
    meta: {
      layout: "auth",
      auth: false,
      role: "student",
    },
  },

  {
    path: "/blog",
    name: "student-blog",
    // component: checkLayout().view,
    component: () => import("../pages/MainBlog/MainBlog.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/main",
    name: "student-main",
    // component: checkLayout().view,
    component: () => import("../pages/LearningPage/LearningPage.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },

  {
    path: "/learning/:courseType/:courseId",
    name: "learning-element-main",
    // component: checkLayout().view,
    component: () => import("../pages/Assigment/AssigmentPage.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/my-space",
    name: "my-spcae-main",
    // component: checkLayout().view,
    component: () => import("../pages/MySpace/MySpace.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/student-profile/:id",
    name: "student-profile-main",
    // component: checkLayout().view,
    component: () => import("../pages/FriendsSpace/FriendsSpace.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/parent-main",
    name: "parent-main",
    component: ParentMain,
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "parent",
    },
  },
  {
    path: "/space-shop",
    name: "shop",
    component: SpaceShopView,
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/space-shop/history",
    name: "shop-history",
    component: SpaceShopHistory,
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },

  {
    path: "/news/:id",
    name: "news",
    component: NewsInfoView,
    meta: {
      layout: checkLayout().layout,
      auth: true,
      role: "student",
    },
  },
  {
    path: "/rating",
    name: "rating",
    component: RatingView,
    meta: {
      layout: "mobile",
      auth: true,
      role: "student",
    },
  },
  {
    path: "/attendance",
    name: "attendance",
    component: AttendanceView,
    meta: {
      layout: "mobile",
      auth: true,
      role: "student",
    },
  },

  {
    path: "/coins-history",
    name: "coins-history",
    component: () => import("../modules/CoinsHistory/CoinsHistory.vue"),
    meta: {
      layout: checkLayout().layout,
      auth: false,
      role: "student",
    },
  },
  {
    path: "/mars-games-typing",
    name: "mars-typing",
    component: () => import("../modules/Games/MarsTyping.vue"),
    meta: {
      layout: "desktop",
      auth: true,
      role: "student",
    },
  },
  {
    path: "/mars-game",
    name: "mars-game",
    component: () => import("../pages/TypingGame/TypingGame.vue"),
    meta: {
      layout: "desktop",
      auth: false,
      role: "student",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
    meta: {
      layout: "auth",
      auth: false,
      role: "student",
    },
  },

  {
    path: "/mars-students-news",
    name: "mars-students-news",
    component: () => import("../modules/NewsPage/NewsPage.vue"),
    meta: {
      layout: "desktop",
      auth: true,
      role: "student",
    },
  },

  {
    path: "/mars-news",
    name: "mars-news",
    component: () => import("@/modules/OpenNews/OpenNews.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/pages/PrivacyPolicy/PrivacyPolicy.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/privacy-policy-student",
    name: "privacy-policy-student",
    component: () => import("@/pages/PrivacyPolicy/PrivacyPolicyNew.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("@/pages/LandingPage/LandingPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/english-test",
    name: "quiz",
    component: () => import("@/pages/QuizPage/QuizPage.vue"),
    meta: {
      layout: "auth",
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.meta.auth;
  const userRole = localStorage.getItem("roleInSpace");

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;

  if (
    requiredAuth &&
    store.getters["auth/isAuth"] &&
    userRole === to.meta.role
  ) {
    next();
  } else if (
    requiredAuth &&
    store.getters["auth/isAuth"] &&
    userRole &&
    userRole !== to.meta.role
  ) {
    next({ name: `${userRole}-main` });
  } else if (requiredAuth && !store.getters["auth/isAuth"]) {
    next("/");
  } else if (store.getters["auth/isAuth"] && to.name === "signup") {
    next({ name: `${userRole}-main` });
  } else {
    next();
  }
});

export default router;
