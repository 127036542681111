<template>
  <div v-if="loading">
    <el-skeleton :rows="10" />
  </div>

  <el-tabs v-else :model-value="activeTab">
    <el-tab-pane :label="$t('rank-levels')" name="rank-levels">
      <div
        v-for="rank in filteredRanks"
        :key="rank?.title"
        class="mb-3 border-b border-[#F4F4F4] pb-3"
      >
        <div class="flex items-center gap-2">
          <img
            class="w-12 h-12 object-contain hover:opacity-100"
            :src="mediaLink + rank.image"
            alt=""
            :class="{
              'opacity-30':
                rank.xp_till > studentXp && activeRank?.title !== rank?.title,
            }"
          />
          <div class="flex-1">
            <p class="text-[20px] font-bold text-[#0C2C57]">
              {{ rank?.title }}
            </p>
            <div v-if="activeRank?.title === rank?.title">
              <div class="flex items-center gap-1">
                <div class="h-3 bg-[#F4F4F4] rounded-full w-full mt-1">
                  <div
                    class="h-3 bg-[#009BF5] rounded-full flex items-center justify-end pr-5"
                    :style="{ width: `${(studentXp / rank.xp_till) * 100}%` }"
                  >
                    <p class="text-[10px] text-white font-[600] leading-[10px]">
                      {{ numeral(studentXp).format("0,0") }} /
                      {{ numeral(rank.xp_till).format("0,0") }}
                    </p>
                  </div>
                </div>
                <img
                  src="@/assets/images/typing-rating/quvvat.png"
                  alt=""
                  class="h-4"
                />
              </div>
            </div>
            <p
              v-else-if="studentXp < rank.xp_till"
              class="text-[16px] text-[#CCCCCC] font-[600]"
            >
              {{ numeral(rank.xp_from).format("0,0a") }} {{ $t("need-xp") }}
            </p>

            <div class="flex items-center justify-between" v-else>
              <p class="text-[16px] text-[#CCCCCC] font-[600]">
                {{ $t("make-success") }}
              </p>
              <div class="flex items-center gap-1">
                <p class="text-[#00CC81] font-[600] text-[16px]">
                  {{ rank.xp_till }}
                </p>
                <img
                  src="@/assets/images/typing-rating/quvvat.png"
                  alt=""
                  class="h-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane :label="$t('rank-rating')" name="rank-rating">
      <RankRating />
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { mediaLink } from "@/apiConfig/mediaLink";
import RankRating from "./RankRating.vue";

import { onMounted, ref } from "vue";
import { getRanks } from "../api";
import numeral from "numeral";
const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  studentXp: {
    type: Number,
    default: 0,
  },
});

const ranks = ref([]);
const filteredRanks = ref([]);
const activeRank = ref(null);
const loading = ref(false);
const activeTab = ref("rank-levels");

async function getRanksData() {
  loading.value = true;
  ranks.value = await getRanks()
    .then((res) => {
      return res.data;
    })
    .catch(() => []);

  sortRanks();
  loading.value = false;
}

onMounted(async () => {
  await getRanksData();
});

const sortRanks = () => {
  ranks.value.forEach((rank) => {
    const taskBadge = rank?.taskbadge[0];

    const findedRank = filteredRanks.value.find(
      (item) => item.title === taskBadge.title
    );

    if (!findedRank) {
      let obj = {
        title: taskBadge.title,
        image: taskBadge.image,
        xp_till: 0,
        xp_from: rank?.xp_from,
      };
      filteredRanks.value.push(obj);
    }

    if (
      findedRank?.title === taskBadge.title &&
      findedRank?.xp_till < rank?.xp_till
    ) {
      findedRank.xp_till = rank?.xp_till;
    }

    filteredRanks.value.sort((a, b) => b.xp_till - a.xp_till);

    const reversedRanks = [...filteredRanks.value].reverse();

    activeRank.value = reversedRanks.find(
      (el) => el.xp_till >= props.studentXp
    );
  });
};
</script>
<style lang="scss">
@import "./style/rank-levels.scss";
</style>
